<!--
 * @Description:  组织结构
 * @Author: wangying
 * @Date: 2021-09-29 09:45:28
-->
<template>
  <div>
    <div class="title">
      <ListTitle :headerNmae="headerNmaeCh" :headerNmaeCh="headerNmae"></ListTitle>
    </div>
    <div class="contentsy contentView">
      <div v-html="content"></div>

    </div>
  </div>
</template>
<script>
// import '@/css/content.less'
import { articleDetail } from '@/api'
import ListTitle from "@/components/reuse/listTitle";

export default {
  components: {
    ListTitle,
  },
  data() {
    return {
      headerNmae: null,
      headerNmaeCh: null,
      content: null
    }
  },
  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)
    this.hub.$emit('scrollToz')

    const params = {
      articleId: 'WZNR201610311925050033',
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      console.log(res.data);
      this.content = res.data.content
      this.headerNmae = res.data.summary
      this.headerNmaeCh = res.data.articleName

    })
  }
}
</script>
<style lang="less" scoped>
.title {
  margin: 50px 0;
}

/deep/ .contentView img {
  max-width: 1020px !important;
}
</style>